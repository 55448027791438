/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
		$(function () {
		  $('[data-toggle="tooltip"]').tooltip();
		});
		
		$('.email').click(function(){
			window.location.href='mailto:'+$(this).html()+'@'+window.location.hostname;
		});
		
		$('.back-to-top a').on('click', function(event){
			event.preventDefault();
			$('body,html').animate({
				scrollTop: 0 ,
				}, 1000
			);
		});
		$('.smart-form').attr('action','/submit');
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

		jQuery("#header #header_nav #menu-main-menu>li a").click(function() {
			if(window.viewport_size!=='xs' && window.viewport_size!=='sm' && $(window).width()>992) {
				var thisLink = jQuery(this);
				var section = (thisLink.text().toLowerCase().trim().replace(" ", "-").replace("& ", "").replace(" ", "-"));

				if(section=='domains') { section = 'domains_section';		 }
				else if(section=='hosting') { section = 'hosting_section';		}
				else if(section=='web-design') { section = 'design_section';}
				else if(section=='programming-development') { section = 'programming_section';}
				else if(section=='web-masters-management') { section = 'security_section';		}
				else if(section=='products-addons') { section = 'product_section';		}
				else if(section=='security') { section = 'security_section';		}
				else { section = 'seo_section'; }
		
				if( !jQuery("#"+section).length  ) {
					section = 'all_others';		
				}
		
				jQuery('html, body').animate({ 
					//console.log(section)
					scrollTop: jQuery("#"+section).offset().top - jQuery('#filter').height()
				}, 1000);
			}
		});
		
		function fitStage() {
			if(jQuery(window).width()<1200) {
			var o_w = 1200,
			n_w = jQuery(window).width();
			ratio = n_w/o_w;
				jQuery('#stage_1,#stage_2,#stage_3,#stage_4').css({
					'-ms-transform': 'scale(' + ratio + ')',
					'-webkit-transform': 'scale(' + ratio + ')',
					'-moz-transform': 'scale(' + ratio + ')',
					'-o-transform': 'scale(' + ratio + ')',
					'transform': 'scale(' + ratio + ')'
				});	
				jQuery('#stage_1').height(ratio*600);
				jQuery('#stage_2, #canvas_2 .vc_empty_space, #stage_3, #canvas_3 .vc_empty_space').height(ratio*376);
				jQuery('#stage_4, #canvas_4 .vc_empty_space').height(ratio*452);
			}else {
				jQuery('#stage_1,#stage_2,#stage_3,#stage_4').removeAttr('style');
			}
		}
	
		jQuery(window).resize(function() {
			fitStage();
		});
		
		jQuery.getScript('https://cdnjs.cloudflare.com/ajax/libs/modernizr/2.8.3/modernizr.min.js', function()  {
			
			 
			if( Modernizr.csstransforms) { 
				

				jQuery('.canvas_ratio_placeholder').css('position','absolute');
				jQuery('#stage_1').show();
				jQuery('#canvas_1').addClass('anime');
				
				jQuery('#canvas_2').addClass('anime')
					.append('<div class="container"><div id="stage_2"><div class="cog-reverse" id="r1_1"><div class="cog" id="r1_2"></div></div></div></div>');
					
					
					
				jQuery('#canvas_3').addClass('anime')
					.append('<div class="container"><div id="stage_3"><div class="cog" id="r2_1"><div class="cog-reverse" id="r2_2"></div></div></div></div>');
					
				jQuery('#canvas_4').addClass('anime')
					.append('<div class="container"><div id="stage_4"><div class="cog-reverse" id="r3_1"><div class="cog-reversex2" id="r3_2"></div></div></div></div>');
					
				
				fitStage();
				
			
			}else {
				jQuery('#stage_1').remove();
			}
			
				 
				 
		});
	 
			
		jQuery('.add_scroll_to_top').append('<div class="scroll_to_top_wrapper"><a href="#" class="cog-reversex2 scroll_to_top"></a></div>');
		

		var $cog = jQuery('.cog'),
			$cog_reverse = jQuery('.cog-reverse'),
			$cog_reversex2 = jQuery('.cog-reversex2'),
			$body = jQuery('html'),
			bodyHeight = $body.height();
			
			if(navigator.userAgent.indexOf("Chrome")!==-1) {
				$body = jQuery(document.body);
				bodyHeight = $body.height();
			}
			else if(navigator.userAgent.indexOf("Safari")!==-1) {
				$body = jQuery("body");
				bodyHeight = $body.height();
			}
			
 
			
		setTimeout(function() {
			$cog = jQuery('.cog');
			$cog_reverse = jQuery('.cog-reverse');
			$cog_reversex2 = jQuery('.cog-reversex2');
			
			$('.back-to-top a, .scroll_to_top').on('click', function(event){
				event.preventDefault();
				$('body,html').animate({
					scrollTop: 0 ,
					}, 1000
				);
			});
		},2400);

		 
		 
 
		jQuery(window).scroll(function () {
			var deg = ($body.scrollTop() / bodyHeight * 360);
			//console.log( deg);
			$cog.css({
				'-ms-transform': 'rotate(' + deg + 'deg)',
				'-webkit-transform': 'rotate(' + deg + 'deg)',
				'-moz-transform': 'rotate(' + deg + 'deg)',
				'-o-transform': 'rotate(' + deg + 'deg)',
				'transform': 'rotate(' + deg + 'deg)',
			});
			$cog_reverse.css({
				'-ms-transform': 'rotate(-' + deg + 'deg)',
				'-webkit-transform': 'rotate(-' + deg + 'deg)',
				'-moz-transform': 'rotate(-' + deg + 'deg)',
				'-o-transform': 'rotate(-' + deg + 'deg)',
				'transform': 'rotate(-' + deg + 'deg)',
			});
			$cog_reversex2.css({
				'-ms-transform': 'rotate(-' + deg*2 + 'deg)',
				'-webkit-transform': 'rotate(-' + deg*2 + 'deg)',
				'-moz-transform': 'rotate(-' + deg*2 + 'deg)',
				'-o-transform': 'rotate(-' + deg*2 + 'deg)',
				'transform': 'rotate(-' + deg*2 + 'deg)',
			});
		});

		
		
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.









/*
0_-> Plugins
1_ -> User Defined Functions
2_ -> Binding Functions 
x_ -> Executes functions 
*/
 
window.viewport_size = jQuery.cookie('viewport'); 
window.$ = jQuery;

documentReadyJobs.push(function(){
	var $viewport_status = jQuery('#viewport_status');
		$viewport_status.css('opacity',0).show();
        smartResizeShowStatusChange();
});

		  
viewportResizeJobs.push(smartResizeShowStatusChange);


function isFunction(functionToCheck) {
 var getType = {};
 return functionToCheck && getType.toString.call(functionToCheck) === '[object Function]';
}

 

function smartResizeShowStatusChange() {
	
	//console.log('screen size:'+viewport_size);
	if (jQuery.cookie('viewport') === undefined || viewport_size=='undefined' || jQuery.cookie('viewport') !== viewport_size) {
		if(viewport_size=='undefined') {
		  	jQuery(window).trigger('resize'); 
		}
		var $viewport_status = jQuery('#viewport_status');
		$viewport_status.hide().css('opacity',1);
		$viewport_status.fadeIn(function(){ 
			setTimeout(function() {
				$viewport_status.fadeOut(function(){
					$viewport_status.css('opacity',0);//.show();
				}); 
			},2000);
		});
		jQuery.cookie('viewport', viewport_size, { expires: 1 });
	}


}

function morphHeader(){

	$body = jQuery('body'); 
	$header = jQuery('#header');	
	$nav = jQuery('#header_nav');
	/*$('#header_nav').affix({
	  offset: {
		top: $('#stage').height()-$('#header_fixed').height(),
		bottom: function () {
		  return (this.bottom = $('#footer').outerHeight(true))
		}
	  }
	});*/
	 
	
	
	if(window.viewport_size!=='xs' && window.viewport_size!=='sm' && $(window).width()>992) {

		$stage = jQuery('#stage');
		$footer = jQuery('#footer');	
		$filler = jQuery('#filler');
		$header_fixed = jQuery('#header_fixed');
		$('#filter').show();
		$logo=jQuery('#logo');
		$map=jQuery('#map');
	
		if(!$header.hasClass('stick-header')) {
			$header.addClass('stick-header');
		}
		
		//var stage_height = jQuery('#stage > div > div > div > div').height();
		var windowTop = jQuery(window).scrollTop();
		var touchPoint = $stage.height()-$header_fixed.height();
		//console.log(windowTop);
		var stageIsEmpty = jQuery("#stage:empty").length;

		if(touchPoint<150) {
			$stage.css('transition','opacity 0.5s linear');	
		}
		var filterOpacity = windowTop/touchPoint;
		
		if(windowTop<=50  ){
			//make logo snall?
			$header.removeClass('small-logo');
			$map.css('opacity',0);
		}
		else {
			$header.addClass('small-logo');
			$map.css('opacity',1);
		}
		
		if(windowTop>=touchPoint) 
		{
			$header.addClass('stick-nav');
			$nav.css('top',$header_fixed.height()+'px');
			$filler.height($nav.height());
			$stage.css('opacity',0);
			
		}
		else 
		{
			$body.addClass('small-header');	
			$header.removeClass('stick-nav');	
			$filler.height(0);
			$stage.css('opacity',1-(filterOpacity<1?filterOpacity:1) );
		}
		/*if(jQuery(document).height()>2000) {
			if((windowTop >= $footer.position().top-100) || (jQuery(window).scrollTop() + jQuery(window).height() == jQuery(document).height()) ) {
				$body.addClass('back-up');	
			}else { 
				$body.removeClass('back-up');	
			}
		}*/

	}else{//if mobile/tablet
		$('#filter').hide();
		if($header.hasClass('stick-header')) {
			$header.removeClass('stick-header');
		}
		if($header.hasClass('stick-nav')) {
			$header.removeClass('stick-nav');
			$nav.css('top','auto');
		}

	}	 
	
} 
documentReadyJobs.push(morphHeader);
viewportResizeJobs.push(morphHeader);
scrollJobs.push(morphHeader);



// @fileRef superpan.js 
loadJobs.push(superPanOnPatrol);
function superPanOnPatrol(){
	if(window.viewport_size=='lg' || window.viewport_size=='xl') { 
		jQuery('body').append('<img id="superpan" src="'+window.cdn+'/less_coffee/img/superpan.gif" style="display:none;" />');
		$superpan=jQuery('#superpan');
		setInterval(function(){
			var middleOfPage=Math.max(0,parseInt((jQuery(window).height()/2)+jQuery(window).scrollTop()))+"px";
			$superpan.css({'position':'absolute','left':'-120px','top':middleOfPage,'z-index':'9999'});
			$superpan.show();
			$superpan.animate({left:jQuery(window).width()+120},((jQuery(window).width()/200)*1000),function(){$superpan.hide();});},30000);
	}
}
 

loadJobs.push(function(){
 	 
    jQuery('a[href="#search"]').on('click', function(event) {
        event.preventDefault();
        jQuery('#search').addClass('open');
        jQuery('#search > form > input[type="search"]').focus();
    });
    
    jQuery('#search, #search button.close').on('click keyup', function(event) {
        if (event.target == this || event.target.className == 'close' || event.keyCode == 27) {
            jQuery(this).removeClass('open');
        }
    });
 
});


// @fileRef 4_flying-penguin.js 
/*loadJobs.push(releaseFlyingPenguin);

function releaseFlyingPenguin(){
	setTimeout(function(){			
	  jQuery.getScript(window.cdn+"/less_coffee/twitter-bird.v1.2.js",function(){
			jQuery('mark').css('display','block');});
	},2000);
}*/

// @fileRef 5_penguin.js 
scrollJobs.push(engageFooterPenguin);function engageFooterPenguin(){ 
	if(jQuery(window).scrollTop()+jQuery(window).height()>=jQuery(document).height()-1){
		jQuery('#footer_penguin').addClass('show');
	}
	else if(jQuery(window).scrollTop()+jQuery(window).height()<=jQuery(document).height()-3){
		jQuery('#footer_penguin').removeClass('show');
		jQuery('#footerSlideContainer').removeClass('open');
		jQuery('#footerSlideContent').animate({height:'0px'});
	}
}
	
documentReadyJobs.push(function(){
/*
	jQuery('#social-tabs').dcSocialTabs({
		
		position: 'absolute',
		location: 'top',
		align: 'right',
		width: 380,
		offset: 130,
		widgets: 'twitter,fblike,facebook,google,rss',
		rssId: 'http://feeds.feedburner.com/ColumbusSoft',
		twitterId: 'columbussoftcom',
		fblikeId: '211786022221924',
		facebook: '211786022221924',
		//fbrecId: 'http://www.columbussoft.com',
		googleId: '111470071138275408587',
		//pinterestId: 'ColumbusSoft',
		twitter: {
				thumb: true
			}
	}); 

*/ 
	// @fileRef footer.js 
 

	jQuery('.neoEmail').click(function(){
		window.location.href='mailto:'+jQuery.trim(jQuery(this).text())+'@columbussoft.com';
	});
	
	jQuery('.neoPhone').click(function(){
		window.location.href='tel://+1'+parseInt(jQuery(this).text().replace(/[^0-9]/g, ''), 10);
	});
	
	jQuery('[title]').each(function() {
		if(jQuery(this).attr('data-toggle')!=="tooltip") {
			jQuery(this).attr('data-bird',jQuery(this).attr('title')).removeAttr('title');		
		}
	});
	
	 

	jQuery('.email').click(function(){
		window.location.href='mailto:'+jQuery(this).html()+'@mydomain.com';
	});
});

loadJobs.push(function(){
	 setTimeout(function() {
		 if(window.viewport_size=='lg' || window.viewport_size=='xl') { 
/*
			window.LHCVotingOptions = {status_text:'Help us grow'};
			(function() {
			var po = document.createElement('script'); po.type = 'text/javascript'; po.async = true;
			po.src = '//livechatbar.com/questionary/getstatus/(position)/middle_right/(top)/400/(units)/pixels/(width)/300/(theme)/1/(height)/300';
			var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(po, s);
			})();
			
			window.LHCFAQOptions = {status_text:'FAQ',url:'replace_me_with_dynamic_url',identifier:''};
			(function() {
			var po = document.createElement('script'); po.type = 'text/javascript'; po.async = true;
			po.src = '//livechatbar.com/faq/getstatus/(position)/middle_right/(top)/450/(units)/pixels/(theme)/1';
			var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(po, s);
			})();
*/

		}
	},3000);
	if(typeof(tBirdJQ)!=='undefined'){
		tBirdJQ.attr('data-content','Howdy! You can mouse over links and I will try to tell you what\'s behind them... ');
	}
	jQuery('[data-bird]').hover(function(){
		if(typeof(tBirdJQ)!=='undefined'){
			if (birdIsFlying) return false;	
			if(jQuery(this).attr('data-bird')==null) {
				tBirdJQ.attr('data-content',"I don't know anything about that yet.");			
			}else {
				tBirdJQ.attr('data-content',jQuery(this).attr('data-bird'));
			}
			tBirdJQ.popover('show');
		}
	}); 
	
	jQuery("html,body").trigger("scroll");//for lazy load since it has treshold!

		
});



