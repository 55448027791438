function isFunction(functionToCheck) {
 var getType = {};
 return functionToCheck && getType.toString.call(functionToCheck) === '[object Function]';
}


//############# SMART RESIZE START ####################
(function($, document, window, viewport){

  // Executes each time window size changes
  jQuery(window).bind('resize', function() {
      viewport.changed(function(){
		  
	  	window.viewport_size = (viewport.is('xs')?'xs':(viewport.is('sm')?'sm':(viewport.is('md')?'md':(viewport.is('lg')?'lg':'xl'))));

		jQuery.each(viewportResizeJobs, function() {
			if(typeof this=='function'){
				this();
			}else{
				console.log('viewportResizeJobs Event is not  a function');	
			}
		});
      });
  });
  
})(jQuery, document, window, ResponsiveBootstrapToolkit);
//############# SMART RESIZE END ####################

//############# DOCUMENT READY START ####################
jQuery(document).ready(function() {
	jQuery.each(documentReadyJobs, function(k,v) {
		if(typeof this=='function') { 
			if(typeof this=='function'){
				this();
			}else{
				console.log('documentReadyJobs Event is not  a function');	
			}
		}
	});
});	
//############# DOCUMENT READY END ####################
 
//############# WINDOW LOAD START ####################
jQuery(window).load(function(){
	jQuery.each(loadJobs, function(a,b) {

		if(typeof this=='function'){
			this();
		}else{
			console.log('loadJobs Event is not  a function');	
		}

	});
});	
//############# WINDOW LOAD END ####################

//############# WINDOW RESIZE START ####################
jQuery(window).bind("resize", function(){
	jQuery.each(resizeJobs, function() {
		if(typeof this=='function'){
			this();
		}else{
			console.log('resizeJobs Event is not  a function');	
		}
	});
});
//############# WINDOW RESIZE END ####################


//############# WINDOW SCROLL START ####################
jQuery(window).scroll(function(){
	jQuery.each(scrollJobs, function(a,b) {
		if(typeof this=='function'){
			this();
		}else{
			console.log('scrollJobs Event is not a function');	
		}
	});
});
//############# WINDOW RESIZE END ####################

//############# DOM Subtree Modified  START ####################
/*jQuery('body').bind("DOMSubtreeModified", function(){
	jQuery.each(documentModJobs, function() {
		if(typeof this=='function') { 
			if(typeof this=='function'){
				this();
			}else{
				console.log('documentModJobs Event is not  a function');	
			}
		}
	});
});*/	
//############# DOM Subtree Modified  END ####################